/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Banner } from 'src/api/entities/banners/types';
import { Container } from 'src/components/core/layout/container';
import { DescriptionBanner } from './shared/description-banner';
import { media } from 'src/styles/media';
import { useEffect } from 'react';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useIsClient } from 'src/context/client';
import { useSessionStorage } from 'src/hooks/use-session-storage';
import styled from 'styled-components';

/**
 * Props type.
 */

type Props = {
  banner?: Banner;
};

/**
 * `ContentProps` type.
 */

type ContentProps = {
  banner: Banner;
  onClose: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  inset: 0 0 auto;
  overflow: hidden;
  position: fixed;
  z-index: var(--z-index-banner);
`;

/**
 * `StyledDescriptionBanner` styled component.
 */

const StyledDescriptionBanner = styled(DescriptionBanner)`
  margin: 0 -8px;
  padding: 6px 0 8px;

  ${media.min.ms`
    margin: 0;
    padding: 6px 24px;
  `}
`;

/**
 * `TopBannerContent` component.
 */

const TopBannerContent = ({ banner, onClose }: ContentProps) => {
  const [ref, { height }] = useElementDimensions<HTMLDivElement>();
  const { backgroundColor, ...bannerData } = banner;

  useEffect(() => {
    if (height) {
      document.documentElement.style.setProperty('--top-banner-height', `${height}px`);
    }

    return () => {
      document.documentElement.style.setProperty('--top-banner-height', '0px');
    };
  }, [height]);

  return (
    <Wrapper
      {...(banner.dismissable && { initial: { height: 0 } })}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      ref={ref}
      {...(backgroundColor && { style: { backgroundColor } })}
    >
      <Container>
        <StyledDescriptionBanner {...bannerData} onClose={onClose} />
      </Container>
    </Wrapper>
  );
};

/**
 * Export `TopBanners` component.
 */

export const TopBanners = ({ banner }: Props) => {
  const isClient = useIsClient();
  const [hideBanner, setHideBanner] = useSessionStorage(`banner-${banner?.id}`);

  return (
    <AnimatePresence>
      {banner && (!banner.dismissable || (isClient && !hideBanner)) && (
        <TopBannerContent banner={banner} onClose={() => setHideBanner(true)} />
      )}
    </AnimatePresence>
  );
};
